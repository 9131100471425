import { MDText } from 'i18n-react';

import { Button, IconText, TickCircleIcon } from '@motorway/mw-highway-code';
import { InfoBox } from '@motorway/mw-highway-code/alpha';
import { Hyperlink } from '@motorway/mw-highway-code/src/components/Hyperlink';

import { PHONES } from 'Components/pages/VehiclePayment/VehiclePayment.helpers';

import LocalTexts from './AuthenticationFeedback.texts';
import { AuthenticationFeedbackProps, AuthenticationFeedbackWithOtpProps } from './AuthenticationFeedback.types';

import styles from './AuthenticationFeedback.module.scss';

const LocalT = new MDText(LocalTexts);

export const AuthenticationFeedback: React.FC<AuthenticationFeedbackProps> = ({
	actions,
	extraContent,
	mainContent,
	show,
	title,
}) => {
	if (!show) return null;
	return (
		<div className={styles.content}>
			<h1 className={styles.contentTitle}>{title}</h1>
			{mainContent}
			{!!actions && (
				<div className={styles.actions}>
					{actions.map((action) => {
						return (
							<Button
								key={action.label}
								fullWidth={true}
								label={action.label}
								onClick={action.onClick}
								variant={action.variant}
							/>
						);
					})}
				</div>
			)}
			{extraContent}
		</div>
	);
};

export const AuthenticationFeedbackWithoutOtp: React.FC = () => {
	return (
		<AuthenticationFeedback
			mainContent={
				<div className={styles.mainContent}>
					<p>{`${LocalT.translate('withoutOtp.content1')}`}</p>
					<p>
						{LocalT.translate('withoutOtp.content2', {
							phoneContact: (
								<Hyperlink
									href={`tel:${PHONES.OTP_SETUP.replace(/\s+/g, '')}`}
									label={PHONES.OTP_SETUP}
									variant="primary"
								/>
							),
						})}
					</p>
				</div>
			}
			show={true}
			title={`${LocalT.translate('withoutOtp.title')}`}
		/>
	);
};

export const AuthenticationFeedbackWithOtp: React.FC<AuthenticationFeedbackWithOtpProps> = ({
	authenticationPhoneNumber,
	onClick,
	showWarning,
}) => {
	return (
		<AuthenticationFeedback
			actions={[
				{
					label: `${LocalT.translate('withOtp.buttonSetupNow')}`,
					onClick,
					variant: 'primary',
				},
			]}
			mainContent={
				<div className={styles.mainContent}>
					{showWarning && (
						<div className={styles.feedback}>
							<InfoBox
								content={LocalT.translate('withOtp.errorMessage', {
									phoneContact: (
										<Hyperlink
											href={`tel:${PHONES.OTP_SETUP.replace(/\s+/g, '')}`}
											label={PHONES.OTP_SETUP}
											variant="primary"
										/>
									),
								})}
								heading={`${LocalT.translate('withOtp.errorHeader')}`}
								variant="error"
							/>
						</div>
					)}
					<p>{`${LocalT.translate('withOtp.content1')}`}</p>
					<p>
						{LocalT.translate('withOtp.content2', {
							phoneEnd: (
								<span className={styles.endPhone}>ending in {`${authenticationPhoneNumber?.slice(-3)}.`} </span>
							),
						})}
					</p>
					<p>
						{LocalT.translate('withOtp.content3', {
							phoneContact: (
								<Hyperlink
									href={`tel:${PHONES.OTP_SETUP.replace(/\s+/g, '')}`}
									label={PHONES.OTP_SETUP}
									variant="primary"
								/>
							),
						})}
					</p>
				</div>
			}
			show={true}
			title={`${LocalT.translate('withOtp.title')}`}
		/>
	);
};

export const OtpFormContent: React.FC<{ otpPhoneEnd: string }> = ({ otpPhoneEnd }) => {
	return (
		<>
			<p>
				{`${LocalT.translate('otpForm.content1', { phoneEnd: otpPhoneEnd ?? '' })}`}
				<br />
				{LocalT.translate('otpForm.content2')}
			</p>
		</>
	);
};

export const OtpFormContentBottom: React.FC = () => {
	return (
		<p>
			{LocalT.translate('otpForm.content3', {
				phoneContact: (
					<Hyperlink href={`tel:${PHONES.OTP_SETUP.replace(/\s+/g, '')}`} label={PHONES.OTP_SETUP} variant="primary" />
				),
			})}
		</p>
	);
};

export const OtpSuccessFormContent: React.FC = () => {
	return (
		<div className={styles.optSuccessForm}>
			<IconText
				icon={(props) => <TickCircleIcon {...props} filled={true} />}
				text={LocalT.translate('otpFormSuccess.title')}
				variant="small--heading3"
			/>
			<div className={styles.optSuccessFormDescription}>
				<p>{`${LocalT.translate('otpFormSuccess.description')}`}</p>
			</div>
		</div>
	);
};
