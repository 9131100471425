import React, { ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';
import cx from 'classnames';
import { MDText } from 'i18n-react';
import { useRouter } from 'next/router';

import { Button, CrossIcon } from '@motorway/mw-highway-code';

import { OtpFormContent, OtpFormContentBottom } from 'Components/AuthenticationFeedback/AuthenticationFeedback';
import { triggerSuccessOTPToast } from 'Components/AuthenticationFeedback/AuthenticationFeedback.helpers';
import OTPContent from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent';
import { OtpAction, OTPContentProps } from 'Components/pages/VehiclePayment/components/OTPContent/OTPContent.types';
import { onRequestCatch } from 'Components/pages/VehiclePayment/components/OTPContent/OTPRequestCatch.helper';
import { getOTPLogParams } from 'Components/pages/VehiclePayment/components/OTPContent/useOTP.helper';
import Texts from 'Components/texts.json';
import { confirmOTPPhoneNumber } from 'Services/api/otp';
import { verifyOTP } from 'Services/api/payment';
import Content from 'Storybook/local/Content/Content';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import { useStoreOtpPhoneValidated } from 'Utilities/hooks/useStoreOtpPhoneValidated';
import useUser from 'Utilities/hooks/useUser';

import styles from './MFAWrapper.module.scss';

const LocalT = new MDText(Texts.MFA);

interface MFAWrapperProps {
	fullScreen?: boolean;
	logs?: OTPContentProps['logs'];
	onBackClick?: () => void;
	onSuccess: () => void;
	portalId?: string;
}

const MFAWrapper = ({
	fullScreen,
	logs = { source: 'accessSensitiveData' },
	onBackClick,
	onSuccess,
	portalId,
}: MFAWrapperProps): JSX.Element => {
	const isPaymentsOTPFeatureEnabled = useFeatureToggle(FEATURES.paymentsOTP);
	const [isStoreOtpPhoneValidated, setIsStoreOtpPhoneValidated] = useStoreOtpPhoneValidated(false);

	const { otpPhone, phoneConfirmedAt } = useUser();
	const router = useRouter();

	const endOfPhone = otpPhone?.slice?.(otpPhone.length - 3, otpPhone.length) || '';
	const [isOTPNumberSetupRequired] = useState(
		(!otpPhone || phoneConfirmedAt === null) && isPaymentsOTPFeatureEnabled && !isStoreOtpPhoneValidated,
	);

	const setUpMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await confirmOTPPhoneNumber({ params: { otpAction: OtpAction.VERIFY_PHONE, verificationCode } });

			triggerSuccessOTPToast();

			return () => {
				setIsStoreOtpPhoneValidated(true);
				onBackClick?.();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	const logInWithMFA: OTPContentProps['request'] = async (verificationCode) => {
		try {
			await verifyOTP({
				logs: getOTPLogParams(router),
				source: 'accessSensitiveData',
				verificationCode,
			});

			return () => {
				onSuccess();
			};
		} catch (error) {
			throw await onRequestCatch({ error });
		}
	};

	let contentDesc: ReactNode = `${LocalT.translate('desc', { number: endOfPhone })}`;
	let onOTPContentRequest: OTPContentProps['request'] = logInWithMFA;
	let title = `${LocalT.translate('title')}`;
	let bottomDesc;

	if (isOTPNumberSetupRequired) {
		contentDesc = <OtpFormContent otpPhoneEnd={endOfPhone} />;
		onOTPContentRequest = setUpMFA;
		title = `${LocalT.translate('titleOTP')}`;
		bottomDesc = <OtpFormContentBottom />;
	}

	const Wrapper = fullScreen ? RemoveScroll : React.Fragment;

	const result = (
		<Wrapper>
			<div className={cx(styles.MFAWrapper, { [styles.fullScreen]: fullScreen })} data-testid="MFA-wrapper">
				<Content className={styles.container}>
					{fullScreen && onBackClick && (
						<div className={styles.toolbar}>
							<Button
								fullWidth
								icon={CrossIcon}
								label="close"
								onClick={onBackClick}
								showLabel={false}
								variant="tertiary"
							/>
						</div>
					)}

					<div className={styles.content}>
						<OTPContent
							bottomDesc={bottomDesc}
							desc={contentDesc}
							extraStyles={{
								paymentsOTPContent: styles.paymentsOTPContent,
								title: styles.title,
							}}
							logs={logs}
							request={onOTPContentRequest}
							title={title}
						/>
					</div>
				</Content>
			</div>
		</Wrapper>
	);

	if (portalId) {
		const container = document.getElementById(portalId);

		if (container) {
			return ReactDOM.createPortal(result, container);
		}
	}

	return result;
};

export default MFAWrapper;
